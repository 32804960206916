<template>

      <!-- Editorial Information Form -->
      <j-form model="staff.contact">

        <!-- Header -->
        <v-col cols="12">
          <h3 class="headline">
            Editorial Information
          </h3>
          <p class="caption">
            This information is only visible to editors.
          </p>
        </v-col>

        <!-- Email -->
        <j-control
          name="email"
          cols="12"
          sm="6"
        />

        <!-- Phone -->
        <j-control
          name="day_phone"
          cols="12"
          sm="6"
        />

        <!-- Address -->
        <j-control name="address" />

        <!-- City -->
        <j-control
          name="city"
          cols="12"
          sm="6"
        />

        <!-- State -->
        <j-control
          name="state"
          cols="12"
          sm="2"
        />

        <!-- Zip -->
        <j-control
          name="zip"
          cols="12"
          sm="4"
        />

        <!-- Contact Notes -->
        <j-control name="contact_notes" />

        <!-- DoB -->
        <j-control
          name="dob"
          cols="12"
          sm="6"
        />

        <!-- Gender -->
        <j-control
          name="gender"
          cols="12"
          sm="6"
        />

        <!-- Privacy -->
        <j-control
          name="privacy" 
          cols="6"
        />

        <!-- Newsletter -->
        <j-control
          name="newsletter"
          cols="6"
          sm="6"
        />

        <!-- Groups -->
        <template v-if="$user.isAdmin">
          <v-col cols="12">
            <h3 class="headline">
              Groups
            </h3>
          </v-col>
          <j-control  name="groups" />
        </template>

      </j-form>

</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('staff')

export default {
  name: 'StaffEditorialForm',
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'contact'
    ])
  }
}
</script>
